import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  HStack,
  Image,
  Input,
  Select,
  Skeleton,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { useDispatch } from 'hooks';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { listUserReviews, updateUserReview } from 'redux/actions/userReviews';
import { ImBin } from 'react-icons/im';
import { MdCameraAlt } from 'react-icons/md';

import ScreenWrapper from '../../components/ScreenWrapper';
import { toast } from '../../navigation/AppRouter';
import { ListUserReviewsSuccess, UserReview } from '../../types';

import { GlobalState } from 'types';

export default function TwistsScreen() {
  const dispatch = useDispatch();
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [approvedFilter, setApprovedFilter] = useState<boolean | null>(null);

  // Keep track of temporary changes to the user review before saving in the debounced function
  type TemporaryChanges = Record<string, { userName?: string; review?: string; image?: null }>;
  const [temporaryChanges, setTemporaryChanges] = useState<TemporaryChanges>({});

  const { userReviews } = useSelector((state: GlobalState) => state.userReviews);

  const loadMore = async () => {
    if (!nextPage) {
      return;
    }

    const response = await dispatch(listUserReviews(nextPage, approvedFilter));

    if (response.error) {
      toast({
        title: 'Error',
        description: 'An error occurred whilst loading more user reviews.',
        status: 'error',
      });
    } else {
      const { payload } = response as ListUserReviewsSuccess;
      setNextPage(payload.next ? nextPage + 1 : null);
    }
  };

  const handleOnFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setApprovedFilter(e.target.value === 'True' ? true : e.target.value === 'False' ? false : null);
    setNextPage(1);
  };

  const onStatusButtonClickHandler = async (u: UserReview, status?: 'accept' | 'reject') => {
    const newValues = temporaryChanges[u.uid] || {};

    const res = await dispatch(
      updateUserReview(u.id, {
        ...newValues,
        ...(status === undefined ? {} : { approved: status === 'accept' }),
      }),
    );

    if (res.error) {
      toast({
        title: 'Error',
        description: 'An error occurred whilst updating the user review.',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    toast({
      title: 'User review updated!',
      description: 'User review updated successfully',
      status: 'success',
      isClosable: true,
    });
  };

  useEffect(() => {
    const getUserReviews = async () => {
      const response = await dispatch(listUserReviews(1, approvedFilter));

      if (!response || response.error) {
        setHasError(true);
      } else {
        const { payload } = response as ListUserReviewsSuccess;
        if (payload.next) {
          setNextPage(2);
        }
      }

      setIsLoading(false);
    };

    getUserReviews();
  }, []);

  useEffect(() => {
    loadMore();
  }, [approvedFilter]);

  if (isLoading) {
    return (
      <ScreenWrapper>
        <Stack>
          {Array.from(Array(5)).map((_, idx) => (
            <Fragment key={idx}>
              <Skeleton height="40px" />
            </Fragment>
          ))}
        </Stack>
      </ScreenWrapper>
    );
  }

  if (hasError) {
    return (
      <ScreenWrapper>
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            An error occurred
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            There was an error loading user reviews. Please refresh to try again.
          </AlertDescription>
        </Alert>
      </ScreenWrapper>
    );
  }

  const getTitle = ({ relatedObjectData }: UserReview) => {
    if (!relatedObjectData) return '';

    const { title, code } = relatedObjectData;
    return `${title} (${code})`;
  };

  return (
    <ScreenWrapper>
      <Stack>
        <HStack spacing="md" align="stretch">
          <VStack spacing="xs" alignItems="flex-start">
            <Text fontWeight="bold">Filter by status</Text>
            <Select
              placeholder="Pending"
              value={
                approvedFilter === true ? 'True' : approvedFilter === false ? 'False' : 'Pending'
              }
              onChange={handleOnFilterChange}
            >
              <option value={'True'}>Approved</option>
              <option value={'False'}>Rejected</option>
            </Select>
          </VStack>
        </HStack>
        <InfiniteScroll
          dataLength={userReviews.length}
          hasMore={nextPage !== null}
          next={loadMore}
          loader={
            <Flex alignItems="center" justifyContent="center" py="sm">
              <Spinner />
              <Text ml="sm">Loading more...</Text>
            </Flex>
          }
          endMessage={
            <Flex alignItems="center" justifyContent="center" py="sm">
              <Text textAlign="center" fontWeight="bold" fontSize="lg">
                Yay! You&apos;ve seen it all
              </Text>
            </Flex>
          }
        >
          <Table flex={1} size="sm">
            <Thead flex={1}>
              <Tr>
                <Td width={'10%'} />
                <Td fontWeight="bold" width={'20%'}>
                  Recipe Code/Title
                </Td>
                <Td fontWeight="bold" width={'30%'}>
                  Comment
                </Td>
                <Td fontWeight="bold" width={'20%'}>
                  Name
                </Td>
                <Td width={'10%'} />
              </Tr>
            </Thead>
            <Tbody>
              {userReviews
                .filter(u => u.approved === approvedFilter)
                .map(u => {
                  const image =
                    temporaryChanges[u.uid]?.image === null ? undefined : u.image?.fullSize;
                  return (
                    <Tr key={`${u.uid}`}>
                      <Td width={'10%'} minWidth="100px" p={0}>
                        <Flex flexDir="row" py={2} justifyContent="flex-start" alignItems="center">
                          {image ? (
                            <Image
                              width="100px"
                              height="100px"
                              boxSize="100px"
                              borderRadius={10}
                              objectFit="cover"
                              src={image}
                              // alt={getTitle(twist)}
                              mr={2}
                            />
                          ) : (
                            <Flex
                              width="100px"
                              height="100px"
                              borderRadius={10}
                              justifyContent="center"
                              alignItems="center"
                              bgColor="gray.200"
                              mr={2}
                            >
                              <MdCameraAlt size={30} color="#b5b5b5" />
                            </Flex>
                          )}
                          {image && (
                            <ImBin
                              cursor="pointer"
                              color="#c33838"
                              fontSize={20}
                              onClick={e => {
                                const existing =
                                  (temporaryChanges && temporaryChanges[u.uid]) || {};

                                setTemporaryChanges({
                                  ...(temporaryChanges || {}),
                                  [u.uid]: { ...existing, image: null },
                                });
                              }}
                            />
                          )}
                        </Flex>
                      </Td>
                      <Td width={'20%'}>
                        <Text>{getTitle(u)}</Text>
                      </Td>
                      <Td width={'30%'}>
                        <Textarea
                          placeholder="User review"
                          value={temporaryChanges[u.uid]?.review || u.review}
                          onChange={e => {
                            const existing = (temporaryChanges && temporaryChanges[u.uid]) || {};

                            setTemporaryChanges({
                              ...(temporaryChanges || {}),
                              [u.uid]: { ...existing, review: e.target.value },
                            });
                          }}
                        />
                      </Td>
                      <Td width={'20%'}>
                        <Input
                          placeholder="User Name"
                          value={temporaryChanges[u.uid]?.userName || u.userName}
                          onChange={e => {
                            const existing = (temporaryChanges && temporaryChanges[u.uid]) || {};

                            setTemporaryChanges({
                              ...(temporaryChanges || {}),
                              [u.uid]: { ...existing, userName: e.target.value },
                            });
                          }}
                        />
                      </Td>
                      <Td width={'10%'}>
                        {approvedFilter === null ? (
                          <HStack>
                            <Button
                              colorScheme="green"
                              onClick={() => onStatusButtonClickHandler(u, 'accept')}
                            >
                              Accept
                            </Button>
                            <Button
                              colorScheme="red"
                              onClick={() => onStatusButtonClickHandler(u, 'reject')}
                            >
                              Reject
                            </Button>
                          </HStack>
                        ) : (
                          <Button colorScheme="green" onClick={() => onStatusButtonClickHandler(u)}>
                            Save
                          </Button>
                        )}
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </InfiniteScroll>
      </Stack>
    </ScreenWrapper>
  );
}
