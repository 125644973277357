import { Divider, Flex, HStack, Text, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { logout } from 'redux/actions/auth';
import { assetUpload } from 'redux/actions/common';
import { createPack } from 'redux/actions/pack';
import { createMethodStep, createRecipe } from 'redux/actions/recipe';

import AssetImportModal, { FileStatus } from 'components/AssetImportModal';
import { useDispatch } from 'hooks';

import { toast } from 'navigation/AppRouter';
import routes from 'navigation/routes';
import { GlobalState, Pack, Recipe } from 'types';
import CreateRecipePackModal from './CreateRecipePackModal';

const Navbar: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const isAuthenticated = useSelector((state: GlobalState) => state.auth.isAuthenticated);

  const [isUploading, setIsUploading] = useState(false);
  const [isCreatePackModalOpen, setIsCreatePackModalOpen] = useState(false);
  const [isCreateRecipeModalOpen, setIsCreateRecipeModalOpen] = useState(false);
  const [isCreatingPack, setIsCreatingPack] = useState(false);
  const [isCreatingRecipe, setIsCreatingRecipe] = useState(false);

  /**
   * List of files to show to the user
   */
  const [files, setFiles] = useState<{
    [key: string]: {
      file: File;
      status: FileStatus;
      message?: string;
    };
  }>({});

  const [fileToUpload, setFileToUpload] = useState<string | null>(null);
  const [assetUploadFailure, setAssetUploadFailure] = useState(false);

  useEffect(() => {
    /**
     * On uploading multiple assets we need to update each upload's individual
     * status (e.g. isUploading/success/failure) when calling the API. This is
     * unreliable in a JavaScript 'for loop' as React queues the state updates
     * and prevents rerender until the 'for loop' has resolved leading to the state
     * updates all happening at once.
     *
     * This useEffect acts as a controlled 'for loop' where we can update React's local
     * state and call the API whenever the 'fileToUpload' state changes (to a non-null value)
     */
    const callAssetUploadAPI = async () => {
      // If 'fileToUpload' is null this signifies we haven't started
      // the multiple API calls
      if (!fileToUpload || !Object.values(files).length) return;

      setAssetUploadFailure(false);

      const file = files[fileToUpload].file;
      const status = files[fileToUpload].status;
      const filenames = Object.keys(files);
      const isFinalFile = filenames.indexOf(fileToUpload) >= filenames.length - 1;

      if (status === 'success') {
        // Don't reupload files that have already been successfully uploaded
        if (isFinalFile) {
          setIsUploading(false);
          setFileToUpload(null);
        }
      }

      setFiles({
        ...files,
        [fileToUpload]: {
          ...files[fileToUpload],
          status: 'isUploading',
        },
      });

      const formData = new FormData();
      formData.append('file', file);
      const response = await dispatch(assetUpload(formData));

      if (response.error) {
        setFiles({
          ...files,
          [fileToUpload]: {
            ...files[fileToUpload],
            status: 'failure',
            message: response.payload?.message || 'Error',
          },
        });
        setAssetUploadFailure(true);
      } else {
        setFiles({
          ...files,
          [fileToUpload]: {
            ...files[fileToUpload],
            status: 'success',
            message: 'Success',
          },
        });
      }

      // If we're not at the end of the filenames list add the next filename
      // and recall the API (as the useEffect's dependency will have changed)
      if (!isFinalFile) {
        setFileToUpload(prev => {
          if (!prev) return null; // If we've cancelled the upload
          return filenames[filenames.indexOf(fileToUpload) + 1];
        });
      } else {
        setIsUploading(false);
        setFileToUpload(null);
        if (assetUploadFailure) {
          toast({
            title: 'Error',
            description: 'Not all Assets were uploaded',
            status: 'error',
            isClosable: true,
          });
        } else {
          toast({
            title: 'Success',
            description: 'All Assets uploaded',
            status: 'success',
            isClosable: true,
          });
        }
      }
    };
    callAssetUploadAPI();
  }, [fileToUpload]);

  const onDrop = (newFiles: File[]) => {
    const filesToAdd = Object.assign(
      {},
      ...newFiles.map(f => ({
        [f.name]: {
          file: f,
          status: 'pending',
        },
      })),
    );

    setFiles({
      ...files,
      ...filesToAdd,
    });
  };

  const removeFile = (name: string) => {
    if (!Object.values(files).length) return;
    const filesToRemain = { ...files };
    delete filesToRemain[name];
    setFiles(filesToRemain);
  };

  const onUpload = async () => {
    if (!Object.values(files).length) return;

    setIsUploading(true);

    // Start with the first file and then let the useEffect function
    // handle the remainder API calls
    setFileToUpload(Object.values(files)[0].file.name);
  };

  const cancelUpload = () => {
    if (fileToUpload && files) {
      setFiles({
        ...files,
        [fileToUpload]: {
          ...files[fileToUpload],
          status: 'pending',
        },
      });
    }
    setFileToUpload(null);
    setIsUploading(false);
  };

  const handleLogout = async () => {
    await dispatch(logout());
    navigate(routes.login);
  };

  const clearFiles = (status: FileStatus) => {
    /**
     * Clear files by their status value
     */
    const newFiles = { ...files };
    if (files) {
      Object.keys(files).forEach(key => {
        if (files[key].status === status) delete newFiles[key];
      });
    }
    setFiles(newFiles);
  };

  const handleCreate =
    (createType: 'pack' | 'recipe' = 'pack') =>
    async (values: Partial<Pack | Recipe>) => {
      setIsCreatingPack(true);
      setIsCreatingRecipe(true);

      const response =
        createType === 'pack'
          ? await dispatch(createPack(values as Partial<Pack>))
          : await dispatch(createRecipe(values as Partial<Recipe>));

      const { payload, error } = response;

      if (error) {
        const errorMessage = payload && 'code' in payload ? payload.code : null;
        toast({
          status: 'error',
          title: 'Error',
          description: errorMessage ? errorMessage[0] : 'Could not create Pack',
          isClosable: true,
        });
      } else {
        toast({
          status: 'success',
          title: 'Success',
          description: `Successfully created ${createType}.`,
          isClosable: true,
        });

        // Add initial recipe step
        if (createType === 'recipe' && payload && 'method' in payload && payload.method !== null) {
          await dispatch(
            createMethodStep(payload.method, {
              numPeople: 2,
              stepNumber: 1,
              tasks: [],
              title: 'Step 1',
            }),
          );
        }

        const newContentId = payload && 'id' in payload ? payload?.id : null;

        setIsCreatePackModalOpen(false);
        setIsCreateRecipeModalOpen(false);

        if (newContentId) {
          navigate(
            `${createType === 'pack' ? routes.packList : routes.recipesList}/${newContentId}`,
          );
        }
      }
      setIsCreatingPack(false);
      setIsCreatingRecipe(false);
    };

  // Don't show the Navbar if the user is not logged in
  if (!isAuthenticated) return <></>;

  return (
    <>
      <Flex w="100%" background="blue.500" padding="sm">
        <HStack spacing="lg" flex={1}>
          <Link to={routes.base} color="white">
            <Text color="white" fontWeight="bold">
              Sorted Packs - CMS
            </Text>
          </Link>
          <Divider orientation="vertical" />
          <Link color="white" to={routes.base}>
            <Text color="white">Home</Text>
          </Link>
          <Link color="white" to={routes.recipesList}>
            <Text color="white"> Recipes</Text>
          </Link>
          <Link color="white" to={routes.twists}>
            <Text color="white"> Twists</Text>
          </Link>
          <Link color="white" to={routes.userReviews}>
            <Text color="white"> Reviews</Text>
          </Link>
          <Link color="white" to={`${routes.packList}`}>
            <Text color="white"> Recipe Packs</Text>
          </Link>
          <Divider orientation="vertical" />
          <Text cursor="pointer" onClick={() => setIsCreatePackModalOpen(true)} color="white">
            Create Recipe Pack
          </Text>
          <Text cursor="pointer" onClick={() => setIsCreateRecipeModalOpen(true)} color="white">
            Create Recipe
          </Text>
          <Text cursor="pointer" onClick={onOpen} color="white">
            Asset Import
          </Text>
        </HStack>
        <Link to={routes.login} onClick={handleLogout}>
          <Text color="white">Logout</Text>
        </Link>
      </Flex>
      <AssetImportModal
        isOpen={isOpen}
        onClose={onClose}
        files={Object.values(files)}
        isUploading={isUploading}
        removeFile={removeFile}
        onUpload={onUpload}
        cancelUpload={cancelUpload}
        onClear={(status?: FileStatus) => {
          if (!status) {
            setFiles({});
          } else {
            clearFiles(status);
          }
        }}
        onDrop={newFiles => {
          onDrop(newFiles);
        }}
      />
      <CreateRecipePackModal
        isOpen={isCreatePackModalOpen}
        onClose={() => setIsCreatePackModalOpen(false)}
        isSubmitting={isCreatingPack}
        onSubmit={handleCreate('pack')}
      />
      <CreateRecipePackModal
        isOpen={isCreateRecipeModalOpen}
        onClose={() => setIsCreateRecipeModalOpen(false)}
        isSubmitting={isCreatingRecipe}
        onSubmit={handleCreate('recipe')}
        isRecipe
      />
    </>
  );
};

export default Navbar;
