// --- All our API URLs live here --- //

import { baseUrl, apiPrefix } from './env';

const api = {
  // Authentication
  login: `${baseUrl}/auth/cms/login/`,
  logout: `${baseUrl}/auth/cms/logout/`,
  verifyToken: `${baseUrl}/auth/verifyToken/`,

  // Packs
  packs: (packId?: number, page?: string | null, filter?: string, search?: string) => {
    if (!page && !filter) {
      return `${apiPrefix}/cms/packs/${packId ? `${packId}/` : ''}`;
    }

    const pageQueryParam = page ? `page=${page}` : '';
    const filterQueryParam = filter || '';
    const paramSeparator = page && filter ? '&' : '';
    return `${apiPrefix}/cms/packs/?${pageQueryParam}${paramSeparator}${filterQueryParam}${
      search ? `&search=${search}` : ''
    }`;
  },

  duplicatePack: (packId: number) => `${apiPrefix}/cms/packs/${packId}/duplicate/`,

  // Pack Recipes
  packRecipes: (packId: number, recipeId?: number) =>
    `${apiPrefix}/cms/packs/${packId}/recipes/${recipeId ? `${recipeId}/` : ''}`,
  packRecipe: (recipeId: number) => `${apiPrefix}/cms/recipes/${recipeId}/`,

  // Recipes
  recipes: (recipeId?: number) => `${apiPrefix}/cms/recipes/${recipeId ? `${recipeId}/` : ''}`,
  recipeList: (page: number, sorting: string, recipe_type: string, search?: string) =>
    `${apiPrefix}/cms/recipes/?page=${page}&ordering=${sorting}&recipe_type=${recipe_type}${
      search ? `&search=${search}` : ''
    }`,

  //twists
  twists: (pageNumber: number, approved?: string) =>
    `${apiPrefix}/cms/twists/?page=${pageNumber}&approved=${approved}`,
  pendingTwists: (pageNumber: number) =>
    `${apiPrefix}/cms/twists/?page=${pageNumber}&approved__isnull=True`,
  twistUpdate: (twistId: number) => `${apiPrefix}/cms/twists/${twistId}/`,
  createTwist: `${apiPrefix}/cms/twists/create/`,

  // User Reviews
  userReviews: (pageNumber: number, approved: boolean | null) =>
    approved !== null
      ? `${apiPrefix}/cms/userReviews/?page=${pageNumber}&approved___isnull=True`
      : `${apiPrefix}/cms/userReviews/?page=${pageNumber}&approved=${approved}`,
  userReview: (id: number) => `${apiPrefix}/cms/userReviews/${id}/`,

  // Recipe Ingredients
  // This also allows Bulk creating if a list is passed through in
  // the request body
  listCreateRecipeIngredients: (recipeId: number) =>
    `${apiPrefix}/cms/recipes/${recipeId}/recipeIngredients/`,

  updateRecipeIngredients: (recipeId: number) => `${apiPrefix}/cms/recipeIngredients/${recipeId}/`,

  // Method Steps
  listCreateMethodSteps: (recipeMethod: number) =>
    `${apiPrefix}/cms/recipeMethods/${recipeMethod}/methodSteps/`,

  editDeleteMethodStep: (methodStepId: number) => `${apiPrefix}/cms/methodSteps/${methodStepId}/`,

  // Step Tasks
  bulkDeleteStepTasks: `${apiPrefix}/cms/stepTask/delete/`,

  // Bulk Update/Create Recipe Ingredients
  bulkUpdateRecipeIngredients: `${apiPrefix}/cms/recipeIngredients/bulk/`,

  // Asset Upload
  assetUpload: `${apiPrefix}/cms/assetUpload/`,

  // Tags
  themes: `${apiPrefix}/cms/tags/theme/`,
  cuisine: `${apiPrefix}/cms/tags/cuisine/`,
  attributes: `${apiPrefix}/cms/tags/attribute/`,

  // Ingredients
  ingredients: `${apiPrefix}/cms/ingredients/`,

  // Units
  units: `${apiPrefix}/cms/units/`,

  // Pack Shopping List
  packShoppingList: (packId: number) => `${apiPrefix}/cms/packs/${packId}/shoppingList/`,

  // Duplicate recipe details for secondary portion
  duplicatePortion: (recipeId: number) => `${apiPrefix}/cms/recipes/${recipeId}/duplicate/`,

  // Search / List Endpoints
  // Packs
  searchPacks: `${apiPrefix}/cms/search/packs`,

  // Convert Method
  convertMethod: `${apiPrefix}/cms/convertMethod/`,
};

export default api;
