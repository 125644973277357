import { combineReducers } from "redux";
import { GlobalState } from "types";
import { authReducer } from "./auth";
import { commonReducer } from "./common";
import { packReducer } from "./pack";
import { recipeReducer } from "./recipe";
import { twistsReducer } from "./twists";
import { userReviewsReducer } from "./userReviews";

const appReducer = combineReducers<GlobalState>({
  auth: authReducer,
  pack: packReducer,
  common: commonReducer,
  recipe: recipeReducer,
  twists: twistsReducer,
  userReviews: userReviewsReducer,
});

const rootReducer: typeof appReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
