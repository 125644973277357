const routes = {
  base: '/',
  ingredients: '/ingredients',
  login: '/login',
  packList: '/packs',
  packs: '/packs/:id',
  recipes: '/recipes/:id',
  recipesList: '/recipes',
  userReviews: '/userReviews',
  twists: '/twists',
};

export default routes;
