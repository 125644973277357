import uniqBy from 'lodash/uniqBy';

import { Action, UserReviewsState } from 'types';
import actionTypes from 'redux/actionTypes';

export const userReviewsReducer = (
  state: UserReviewsState = {
    userReviews: [],
  },
  action: Action,
): UserReviewsState => {
  switch (action.type) {
    case actionTypes.LIST_USER_REVIEWS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        userReviews: uniqBy([...state.userReviews, ...payload.results], 'uid'),
      };
    }

    case actionTypes.UPDATE_USER_REVIEWS_SUCCESS: {
      const { payload } = action;
      const updatedUserReviews = state.userReviews.map(review =>
        review.uid === payload.uid ? { ...review, ...payload } : review,
      );
      return {
        ...state,
        userReviews: updatedUserReviews,
      };
    }

    default:
      return state;
  }
};
