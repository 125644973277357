import API from 'constants/api';
import { decamelizeKeys } from 'humps';
import actionTypes from 'redux/actionTypes';
import { ListUserReviewsAction, UpdateUserReviewAction } from 'types';
import { callAPI } from 'utils';

export const listUserReviews = (pageNumber: number, approved: boolean | null) => {
  return callAPI<ListUserReviewsAction>({
    types: [
      actionTypes.LIST_USER_REVIEWS_REQUEST,
      actionTypes.LIST_USER_REVIEWS_SUCCESS,
      actionTypes.LIST_USER_REVIEWS_FAILURE,
    ],
    endpoint: API.userReviews(pageNumber, approved),
    method: 'GET',
  });
};

export const updateUserReview = (id: number, data: any) => {
  return callAPI<UpdateUserReviewAction>({
    types: [
      actionTypes.UPDATE_USER_REVIEWS_REQUEST,
      actionTypes.UPDATE_USER_REVIEWS_SUCCESS,
      actionTypes.UPDATE_USER_REVIEWS_FAILURE,
    ],
    endpoint: API.userReview(id),
    method: 'PATCH',
    body: decamelizeKeys(data),
  });
};
